* {
  font-family: 'Nunito', sans-serif;
}

.authForm {
  background-color: #f4c437;
  width: 100%;
  height: 100vh;
}

.image-form {
  padding: 3em;
}

.set-form {
  border-top-left-radius: 42px;
  border-bottom-left-radius: 42px;
  padding: 3em;
}

.set-image-form {
  width: 60%;
  height: auto;
  margin: 10px auto;
}

.bg-button-form {
  background-color: #f4c437;
}

.set-logo-login {
  width: 30%;
}

@media only screen and (max-width: 600px) {
  .set-form {
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;
    border-bottom-left-radius: 0px;
    /* padding: 3em; */
  }
}

@media (max-width: 639px) {
  .set-form {
    padding: 20px;
  }

  .auth-img {
    height: 30vh;
  }

  .auth-body {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    min-height: 70vh;
  }

  .auth-img-forgot {
    height: 40vh;
  }

  .auth-body-forgot {
    height: 60vh;
  }

  .set-image-form {
    width: 55%;
  }
}
